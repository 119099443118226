import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: './home/home.module#HomePageModule' },
  { path: 'professional-experience', loadChildren: './pages/professional-experience/professional-experience.module#ProfessionalExperiencePageModule' },
  { path: 'skills', loadChildren: './pages/skills/skills.module#SkillsPageModule' },
  { path: 'education', loadChildren: './pages/education/education.module#EducationPageModule' },
  { path: 'portfolio', loadChildren: './pages/portfolio/portfolio.module#PortfolioPageModule' },
  { path: 'portfolio-blog', loadChildren: './pages/portfolio-blog/portfolio-blog.module#PortfolioBlogPageModule' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
